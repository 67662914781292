import React, { Component } from "react";

import Layout from "../layouts/ja";
import SEO from "../components/seo";
import SignUp from "../services/SignUp";
import SignUpLang from "../data/static-pages/pages/signup/ja";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { navigate } from "gatsby";
import moment from "moment";

const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";

class SignUpPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.urlRedirect = "/ja/verify-account";
    this.termsUrl = "/ja/terms-and-conditions-8";
    this.langKey = this.props.pageContext.langKey;
  }

  static getLoginOptions() {
    const referral =
      typeof window !== "undefined" &&
      new URLSearchParams(window.location.search).get("referral");
    const redirectUri =
      typeof window !== "undefined" && `${window.location.origin}/ja/callback`;

    return {
      loginOptions: {
        authorizationParams: {
          screen_hint: "signup",
          ui_locales: "ja",
          referral,
          redirect_uri: redirectUri,
        },
      },
    };
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Sign Up " />

        <SignUp
          location={this.props.location}
          urlRedirect={this.urlRedirect}
          langData={SignUpLang}
          langKey={this.langKey}
          termsUrl={this.termsUrl}
        />
      </Layout>
    );
  }
}

export default isAuth0Enabled
  ? withAuthenticationRequired(SignUpPage, SignUpPage.getLoginOptions())
  : SignUpPage;
